export const Softener = (props: { width?: string; height?: string }) => (
  <svg
    width={props.width || "98"}
    height={props.height || "73"}
    viewBox="0 0 98 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.6033 0C28.5544 0 12.1508 16.4036 12.1508 36.4524H0L18.2262 60.7541L36.4524 36.4524H24.3016C24.3016 22.965 35.1159 12.1508 48.6033 12.1508V0ZM78.9803 12.1508L60.7541 36.4524H72.9049C72.9049 49.9398 62.0907 60.7541 48.6033 60.7541V72.9049C68.6521 72.9049 85.0557 56.5013 85.0557 36.4524H97.2065L78.9803 12.1508Z"
      fill="black"
    />
  </svg>
);

export const Purifier = (props: { width?: string; height?: string }) => (
  <svg
    width={props.width || "64"}
    height={props.height || "87"}
    viewBox="0 0 64 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.6006 0L28.0192 3.5814C26.8605 4.74009 0 31.9166 0 54.9851C0 72.3654 14.2203 86.5857 31.6006 86.5857C48.9809 86.5857 63.2012 72.3654 63.2012 54.9851C63.2012 32.022 36.3407 4.74009 35.182 3.5814L31.6006 0ZM15.8003 49.7183C18.7497 49.7183 21.0671 52.0357 21.0671 54.9851C21.0671 60.7785 25.8072 65.5186 31.6006 65.5186C34.55 65.5186 36.8674 67.836 36.8674 70.7854C36.8674 73.7348 34.55 76.0521 31.6006 76.0521C20.0137 76.0521 10.5335 66.572 10.5335 54.9851C10.5335 52.0357 12.8509 49.7183 15.8003 49.7183Z"
      fill="black"
    />
  </svg>
);

export const Upgraded = (props: { width?: string; height?: string }) => (
  <svg
    width={props.width || "78"}
    height={props.height || "78"}
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V9.6363H77.0904V0H0ZM38.5452 19.2726L9.6363 48.1815H28.9089V77.0904H48.1815V48.1815H67.4541L38.5452 19.2726Z"
      fill="black"
    />
  </svg>
);

export const Twitter = (props: { size?: string }) => (
  <svg
    width={props.size || "34"}
    height={props.size || "34"}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 28.0703C2.6243 29.074 14.3822 36.6492 22.7431 30.8108C31.1038 24.9724 30.6426 14.9137 30.6426 10.3045C31.4 8.53111 33 7.62955 33 2.82946C31.4284 4.45205 29.8663 5.00414 28.3137 4.48574C26.7926 1.89371 24.6998 0.747263 22.0353 1.04644C18.0386 1.49519 16.5763 5.87765 17.0067 11.5475C10.8441 15.0305 6.01179 9.02259 3.52141 4.48574C2.68932 8.05873 1.88699 12.3483 3.52141 17.0937C4.61103 20.2571 7.23032 22.9317 11.3793 25.1173C7.17457 27.6643 3.7148 28.6486 1 28.0703Z"
      stroke="#1DA1F2"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
);

export const MenuSvg = () => (
  <svg
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2490"
    width="32"
    height="32"
  >
    <path
      d="M916.238694 290.722314 138.030703 290.722314c-11.30344 0-20.466124-9.162684-20.466124-20.466124s9.162684-20.466124 20.466124-20.466124L916.238694 249.790066c11.30344 0 20.466124 9.162684 20.466124 20.466124S927.542134 290.722314 916.238694 290.722314z"
      p-id="2491"
      fill="#ffffff"
    ></path>
    <path
      d="M936.702772 495.52477c0 11.307533-9.15859 20.466124-20.466124 20.466124l-778.203898 0c-11.307533 0-20.466124-9.15859-20.466124-20.466124 0-11.2973 9.15859-20.466124 20.466124-20.466124l778.203898 0C927.544181 475.058646 936.702772 484.22747 936.702772 495.52477z"
      p-id="2492"
      fill="#ffffff"
    ></path>
    <path
      d="M916.238694 741.263567 138.030703 741.263567c-11.30344 0-20.466124-9.162684-20.466124-20.466124s9.162684-20.466124 20.466124-20.466124L916.238694 700.331319c11.30344 0 20.466124 9.162684 20.466124 20.466124S927.542134 741.263567 916.238694 741.263567z"
      p-id="2493"
      fill="#ffffff"
    ></path>
  </svg>
);
